import Detail from '../views/pages/detail';
import Explore from '../views/pages/explore';
import Favorite from '../views/pages/favorite';
import Home from '../views/pages/home';
import NotFound from '../views/pages/not-found';

const routes = {
  '/': { page: Home, title: 'Home' },
  '/explore': { page: Explore, title: 'Explore Restaurants' },
  '/favorite': { page: Favorite, title: 'Favorite Restaurants' },
  '/detail': { page: Detail, title: 'Restaurant Detail' },
  404: { page: NotFound, title: '404 Not Found' },
};

const parseUrl = (url) => {
  const parts = url.split('/');
  return {
    page: parts[1] || '',
    resourceId: parts[2] || '',
  };
};

const getCurrentRoute = () => {
  const url = (window.location.hash || '#/').slice(1);
  const parsedUrl = parseUrl(url);
  const page = `/${parsedUrl.page}`;
  const route = routes[page] || routes[404];
  const params = {};
  if (parsedUrl.resourceId) {
    params['id'] = parsedUrl.resourceId;
  }
  route.params = params;
  return route;
};

export { routes, getCurrentRoute, parseUrl };
