import autoBind from 'auto-bind';

class AppHero extends HTMLElement {
  constructor() {
    super();
    autoBind(this);
  }

  render() {
    this.innerHTML = `
      <div class="hero">
        <picture>
          <source media="(max-width: 768px)" type="image/webp" srcset="./images/heros/hero-image_4-small.webp">
          <source media="(max-width: 768px)" type="image/jpeg" srcset="./images/heros/hero-image_4-small.jpg">
          <source type="image/webp" srcset="./images/heros/hero-image_4-large.webp">
          <source type="image/jpeg" srcset="./images/heros/hero-image_4-large.jpg">
          <img fetchpriority="high" alt="Hero image 4" class="active" src="/images/heros/hero-image_4-large.jpg" />
        </picture>
        <div class="caption">
          Explore Local, Savor Global
        </div>
      </div>
    `;
  }

  connectedCallback() {
    this.render();
    this.intervalId = setInterval(this.showNextSlide, 3000);
  }

  disconnectedCallback() {
    clearInterval(this.intervalId);
  }

  showNextSlide() {
    this.slides = this.slides || this.querySelectorAll('img');
    const slides = this.slides;
    for (let i = 0; i < slides.length; i++) {
      const slide = slides[i];
      if (slide.classList.contains('active')) {
        slide.classList.remove('active');
        const nextSlide = slides[(i + 1) % slides.length];
        nextSlide.classList.add('active');
        break;
      }
    }
  }
}

customElements.define('app-hero', AppHero);
