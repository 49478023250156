import autoBind from 'auto-bind';
import FavoriteRestaurantIdb from '../../data/favorite-restaurant-idb';
import Swal from 'sweetalert2';

class FavoriteButton extends HTMLElement {
  constructor() {
    super();
    autoBind(this);
  }

  get restaurant() {
    return this._restaurant;
  }

  set restaurant(value) {
    if (typeof value === 'object') {
      this._restaurant = value;
      this.render();
    }
  }

  async render() {
    this._active = await this.checkRestaurant(this._restaurant.id);
    const active = this._active;
    const label = active ? 'Remove from favorites' : 'Add to favorites';
    const classes = active ? 'active' : '';
    this.innerHTML = `
      <button class="favorite-button ${classes}" aria-label="${label}">
        <i class="${active ? 'fas' : 'far'} fa-bookmark" aria-hidden="true"></i> ${label}
      </button>
    `;

    const favButton = this.querySelector('.favorite-button');
    favButton.onclick = this.toggleFavorite;
  }

  async checkRestaurant(id) {
    const restaurant = await FavoriteRestaurantIdb.getRestaurant(id);
    return !!restaurant;
  }

  async toggleFavorite() {
    const name = this._restaurant.name;
    if (this._active) {
      // delete from IDB
      await FavoriteRestaurantIdb.deleteRestaurant(this._restaurant.id);
      await Swal.fire(
        'Removed!',
        `${name} has been removed from your favorite`,
        'success'
      );
    } else {
      // add to IDB
      await FavoriteRestaurantIdb.putRestaurant(this._restaurant);
      await Swal.fire(
        'Added!',
        `${name} has been added to your favorite`,
        'success'
      );
    }

    // rerender the button
    this.render();
  }
}

customElements.define('favorite-button', FavoriteButton);
