function generateStars(rating) {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 !== 0;
  let stars = '';

  for (let i = 0; i < fullStars; i++) {
    stars += '<i class="fas fa-star"></i>';
  }

  if (halfStar) {
    stars += '<i class="fas fa-star-half-alt"></i>';
  }

  for (let i = fullStars + halfStar; i < 5; i++) {
    stars += '<i class="far fa-star"></i>';
  }

  return stars;
}

function generateExcerpt(description) {
  const maxLength = 100;
  const excerpt = `${description}`.slice(0, maxLength);
  return `${excerpt}...`;
}

export { generateStars, generateExcerpt };
