import autoBind from 'auto-bind';

class AppNav extends HTMLElement {
  constructor() {
    super();
    autoBind(this);
  }

  render() {
    this.innerHTML = `
      <button button type="button" class="menu-btn" title="Open Menu">
        <i class="fas fa-bars"></i>
      </button>

      <nav>
        <button type="button" class="close-btn" title="Close Menu">
          <i class="fas fa-times"></i>
        </button>

        <a href="#/"> Home </a>
        <a href="#/favorite"> Favorite </a>
        <a href="https://github.com/arasyi" target="_blank"> About Us </a>
      </nav>

      <div class="overlay"></div>
    `;
  }

  connectedCallback() {
    this.render();
    this.addEventListener('click', this.toggleMenu);
  }

  disconnectedCallback() {
    this.removeEventListener('click', this.toggleMenu);
  }

  toggleMenu() {
    // only run on mobile
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    if (!mediaQuery.matches) return;

    const menuIcon = this.querySelector('.menu-btn');
    const nav = this.querySelector('nav');
    const overlay = this.querySelector('.overlay');

    menuIcon.classList.toggle('active');
    nav.classList.toggle('active');
    overlay.classList.toggle('active');
  }
}

customElements.define('app-nav', AppNav);
