class AppBar extends HTMLElement {
  constructor() {
    super();
  }

  render() {
    this.innerHTML = `
      <h1><a href="#/">FeastFinder</a></h1>
      <app-nav></app-nav>
    `;
  }

  connectedCallback() {
    this.render();
  }
}

customElements.define('app-bar', AppBar);
